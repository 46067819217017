.map-view {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.auto-complete {
  padding: 20px 0 10px;
}
